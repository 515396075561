export default {
    //跳转付款完毕后地址
    goPayFinishUrl(proxyCode) {
        return `https://www.handebook.com/web/#/${proxyCode}/payFinish/`;
    },
    // //跳转智慧调剂地址
    // goSmartAdjustUrl(proxyCode, universityCode, collegeCode, majorCode, majorLearningWay, politicalScore, englishScore, majorScore1, majorScore2) {
    //     return encodeURI(`https://www.handebook.com/web/#/${proxyCode}/smartAdjust?universityCode=${universityCode}&collegeCode=${collegeCode}&majorCode=${majorCode}&majorLearningWay=${majorLearningWay}&politicalScore=${politicalScore}&englishScore=${englishScore}&majorScore1=${majorScore1}&majorScore2=${majorScore2}&`);
    // },
    //跳转首页地址
    goHomeUrl(proxyCode) {
        return encodeURI(`${window.location.protocol}//www.handebook.com/web/#/${proxyCode}/home`);
    },
    //跳转文档支付地址
    goDocumentPaymentUrl(proxyCode, documentGuid) {
        return encodeURI(`${window.location.protocol}//www.handebook.com/web/#/${proxyCode}/submitDocumentOrder?productGuid=${documentGuid}`);
    },
    //跳转联系商家阅读地址
    goDocumentRegistUrl(proxyCode, documentGuid) {
        return encodeURI(`${window.location.protocol}//www.handebook.com/web/#/${proxyCode}/regist?documentGuid=${documentGuid}`);
    },
    //跳转文档打印地址
    goDocumentPrintUrl(proxyCode, documentGuid) {
        return encodeURI(`${window.location.protocol}//www.handebook.com/web/#/${proxyCode}/print?documentGuid=${documentGuid}`);
    },
    //跳转文档试看地址
    goDocumentPreviewUrl(proxyCode, documentGuid) {
        return encodeURI(`${window.location.protocol}//www.handebook.com/web/#/${proxyCode}/document/${documentGuid}`);
    },
    //跳转课程试看地址
    goCoursePreviewUrl(proxyCode, courseGuid) {
        return encodeURI(`${window.location.protocol}//www.handebook.com/web/#/${proxyCode}/course/${courseGuid}`);
    },
    //跳转文档完整预览地址
    goDocumentViewerUrl(proxyCode, documentGuid, token) {
        return encodeURI(`${window.location.protocol}//handebook.qingstudy.cn/web/#/${proxyCode}/viewer?documentGuid=${documentGuid}&token=${token}`);
    },
    //跳转课程完整播放地址
    goCourseVideoUrl(proxyCode, courseGuid, token) {
        return encodeURI(`${window.location.protocol}//v.handebook.com/web/#/${proxyCode}/video?courseGuid=${courseGuid}&token=${token}`);
    },
}